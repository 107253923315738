import React from "react";
import DomainTag from "./DomainTag";

const DomainTagsList = (props) => {
  return (
    <div>
      {props.domains.map((domain) => {
        return (
          <DomainTag key={domain.airtableId} {...domain} />
        );
      })}
    </div>
  );
}

export default DomainTagsList;