import React from "react";
import { Navbar, NavbarText } from "reactstrap";

const Footer = (props) => {
  return (
    <div>
      <Navbar color="dark" dark expand="md">
        <NavbarText className="text-white">
          &copy; {(new Date()).getFullYear()} <a href="https://appliedresearch.jhu.edu">Center for Applied Public Research</a>, Zanvyl Krieger School of Arts &
          Sciences, Johns Hopkins University 
        </NavbarText>
      </Navbar>
    </div>
  );
}

export default Footer;
