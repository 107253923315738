import React from "react";
import { Button, Card, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";


const RoleCard = (props) => {
  return (
    <Col sm="4" className="text-center mb-2">
      <Card className="hvr-shadow" body>
        <h6>
          <FontAwesomeIcon icon={faUserTie} className="mr-3" />
          Role / Occupation / Job Title
        </h6>

        <h4 className="navy medium-headline">{props.name}</h4>

        <h1 className="orange-number mr-1 mx-0">{props.useCaseCount}</h1>

        <h5>outcomes for this role.</h5>

        <Link to={`/role/${props.airtableId}`} component={Button} className="blue-button">
          View outcomes
        </Link>
      </Card>
    </Col>
  );
}

export default RoleCard;