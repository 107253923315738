import React from "react";
import "./style.css";

import LandingPage  from "./components/pages/LandingPage";

import UseCasesPage from "./components/pages/UseCasesPage";
import UseCasePage from "./components/pages/UseCasePage";

import DataStandardsPage from "./components/pages/DataStandardsPage";
import DataStandardPage from "./components/pages/DataStandardPage";

import CitiesPage from "./components/pages/CitiesPage";
import CityPage from "./components/pages/CityPage"
// import CityAllPage from "./components/pages/CityAllPage"

import TopicPage from "./components/pages/TopicPage";
import TopicsCaseResultsPage from "./components/pages/TopicCaseResultsPage";
import RolePage from "./components/pages/RolePage";

import AboutProjPage from "./components/pages/AboutProjectPage";
import AboutDSPage from "./components/pages/AboutDSPage";
import MethodPage from "./components/pages/MethodologyPage";
import { BrowserRouter as Router, Switch, Route, BrowserRouter } from "react-router-dom";
import TopNav from "./components/navs/TopNav";
import Footer from "./components/Footer";
import RoleCardsListFunctionalWithFilter from "./components/lists/RoleCardsList_functional_with_filter";
import TestContainerPage from "./components/pages/TestContainerPage";
import ScrollToTop from "./components/navs/ScrollToTop";

export default class App extends React.Component {
  render() {
    return (
      <>
        <TopNav />
        <BrowserRouter>
          <Router>
            <ScrollToTop />
            <div>
              <Switch>
                <Route path="/" exact component={LandingPage} />
                <Route path={["/outcomes/:type/:id", "/usecases/:type/:id"]} component={UseCasesPage} />

                <Route path={["/outcomes", "/usecases"]} component={UseCasesPage} />
                <Route path={["/outcome/:id", "/usecase/:id"]} component={UseCasePage} />

                <Route path="/standards" component={DataStandardsPage} />
                <Route path="/standard/:id" component={DataStandardPage} />

                <Route path="/cities/:mode" component={CitiesPage} />
                <Route path="/cities" component={CitiesPage} />
                <Route path="/city/:id/:mode" component={CityPage} />
                <Route path="/city/:id" component={CityPage} />

                <Route path="/about-project" component={AboutProjPage} />
                <Route path="/about-ds" component={AboutDSPage} />
                <Route path="/about-methodology" component={MethodPage} />

                <Route path="/outcomes/filter" component={TestContainerPage} />

                <Route path="/testSearch" component={TestContainerPage} />
                <Route path-="/rolesearch" component={RoleCardsListFunctionalWithFilter} />
                <Route path="/topic/:id" component={TopicPage} />
                <Route path="/topic-caseresults/:id" component={TopicsCaseResultsPage} />
                <Route path="/role/:id" component={RolePage} />
              </Switch>
            </div>
          </Router>
        </BrowserRouter>
        <Footer />
      </>
    );
  }
}