import React from "react";
import { ListGroup } from "reactstrap";
import CityDataSourceListItem from "./CityDataSourceListItem";
import GatesUtilities from "./GatesUtilities";

// by default this list doesn't display items which are missing a .url attribute
//   you can show them by including a 'showAll' attribute, e.g. <CityDataSourcesList data={} showAll />
// you can pass children to this element which will be displayed if there are no data values to show (typically an error message)
const CityDataSourcesList = (props) => {
  let dataSources = GatesUtilities.sortByAttribute(props.data, "name");
  if (!props.showAll) {
    dataSources = dataSources.filter(dataSource => dataSource.url);
  }
  if (dataSources && dataSources.length > 0) {
    return (
      <ListGroup>
        {dataSources.map((cityDataSource) => {
          return (<CityDataSourceListItem key={cityDataSource.airtableId} {...cityDataSource} />)
        })}
      </ListGroup>
    ) 
  } else {
    return (<div>{props.children}</div>);
  }
}

export default CityDataSourcesList;