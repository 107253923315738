import React from "react";
import BackToTop from "../navs/BackToTop";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

import TextFilterableList from "../TextFilterableList";
import DataStandardCardsList from "../lists/DataStandardCardsList";

import dataStandards from "../../data/data_standards.json";

const DataStandardsPage = (props) => {
  return (
    <>
      <BackToTop />
      <div className="bg-header blackwhite-img  mx-auto">
        <Container>
          <h1 className="main-header-text">Data Standards</h1>
          <h2 className="secondary-header-text">Discover the data standards we have collected or created to support economic mobility impacts.</h2>
        </Container>
      </div>
      <div>
        <Container className="pt-4">

        <h2 className="mt-3">Search for Data Standards</h2>
        
          <p>
            In the context of economic mobility, standardized data can help
            public sector employees make service-related decisions more
            quickly; collaborate with other cities or counties; and benchmark
            their own progress. <Link to="/about-ds">Learn more about data standards</Link>.
          </p>
          <TextFilterableList as={DataStandardCardsList} searchableAttributes={['name']} data={dataStandards} />
        </Container>
      </div>
    </>
  )
}

export default DataStandardsPage;