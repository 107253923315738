import React from "react";
import { ListGroup } from "reactstrap";
import UseCaseCityDataSourceListItem from "./UseCaseCityDataSourceListItem";
import GatesUtilities from "./GatesUtilities";

const UseCaseCityDataSourceList = (props) => {
  if (props.data && props.data.length > 0) {
    const useCaseCityDataSources = GatesUtilities.sortByAttribute(props.data, "name")
    return (
      <ListGroup>
        {useCaseCityDataSources.map((cityDataSource) => {
          return (<UseCaseCityDataSourceListItem key={cityDataSource.airtableId} {...cityDataSource} />)
        })}
      </ListGroup>
    );
  } else {
    return <>{props.children}</>
  }
}

export default UseCaseCityDataSourceList;