import React from "react";
import dataStandards from "../../data/data_standards.json";
import useCases from "../../data/use_cases_details.json";
import BackToTop from "../navs/BackToTop";
import { Alert } from "react-bootstrap";
import { Container } from "reactstrap";
import aprLogo from "../../images/logo_apr_full.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faExclamationCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import UseCaseCardsList from "../lists/UseCaseCardsList";
import DataStandardTableList from "../DataStandardTableList";
import GatesUtilities from "../GatesUtilities";

const DataStandardPage = (props) => {
  let dataStandard = undefined;
  let relatedUseCases = undefined;

  dataStandard = dataStandards.find(
    (dataStandard) => dataStandard.airtableId === props.match.params.id
  );

  if (!dataStandard) {
    return <div>Unable to display the requested standard</div>;
  } else {
    relatedUseCases = GatesUtilities.filterUseCases(
      useCases,
      "datastandard",
      dataStandard.airtableId
    );
    return (
      <>
        <BackToTop />
        <div className="mb-4">
          <div className="bg-header blackwhite-img mx-auto">
            <Container>
              <h2 className="secondary-header-text">Data Standard</h2>
              <h1 className="main-header-text internal-txt">
                {dataStandard.name}
              </h1>
            </Container>
          </div>
          <Container>
            <h5 className="mt-4 mb-4">
              {dataStandard.draftedByCivicImpact ? (
                <div>
                  <img
                    className="apr-logo mr-3"
                    src={aprLogo}
                    alt="Applied Public Research logo"
                  />
                  <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                  This data standard was developed by the Center for Applied
                  Public Research.
                </div>
              ) : (
                <div>
                  <Alert variant="warning">
                    <Alert.Heading>
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="mr-2"
                      />
                      This data standard was <strong>not</strong> developed by
                      the Center for Applied Public Research.
                    </Alert.Heading>
                    <hr />
                    We collected the information below as part of our research.
                    It may be inaccurate or out of date. <br />
                    <br />
                    <Alert.Link href={dataStandard.url} target={"_blank"}>
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className="mr-1"
                      />
                      Visit the official source
                    </Alert.Link>
                    .
                  </Alert>
                </div>
              )}
            </h5>

            <h3>About this data standard</h3>
            <p className="mt-3 mb-3">
              {dataStandard.isFederal && (
                <Alert variant="success">
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className="mr-2"
                  />
                  This data is published by the U.S. Federal Government. Since
                  it is standardized nationally, we consider it to be a de facto
                  standard.
                </Alert>
              )}
              {dataStandard.description}
            </p>

            <h3>City Decisions this data might support</h3>
            <div className="mt-3">
              {relatedUseCases.length === 0 ? (
                <Alert variant="danger">
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className="mr-2"
                  />
                  Oh no! We are unable to display any outcomes related to this
                  data standard. This is likely an error in our data, and we
                  apologize for the inconvenience.
                </Alert>
              ) : (
                <>
                  The following{" "}
                  {relatedUseCases.length === 1 ? (
                    <>decision</>
                  ) : (
                    <>{relatedUseCases.length} decisions</>
                  )}{" "}
                  can benefit from this data when combined with other sources.
                  <UseCaseCardsList
                    data={relatedUseCases}
                    className="mb-5"
                    displayCount
                  ></UseCaseCardsList>
                </>
              )}
            </div>

            <h3 className="mt-5">Technical specification</h3>
            <DataStandardTableList data={dataStandard.tables} />
          </Container>
        </div>
      </>
    );
  }
};

export default DataStandardPage;
