import React from "react";
import { Alert} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import UseCaseCityDataSourceList from "./UseCaseCityDataSourceList";
import DataStandardList from "./DataStandardList";

const UseCaseDataSourceListItem = (props) => {

  // city datasources list should exclude items without URLs.
  let myCityDataSources = undefined;
  if (props.cityDataSources) {
    myCityDataSources = props.cityDataSources.filter(cityDataSource => cityDataSource.url);
  }

  return (
    <div className="mb-4">
      <h4 className="light-orange medium-headline mt-5">
        <FontAwesomeIcon icon={faDatabase} className="mr-3" />
        {props.name}
      </h4>
      <h5 className="dark-gray medium-headline mb-3x">
        Examples of this data:
      </h5>

      {/* show list if it exists / has items; otherwise display alert */}
      <UseCaseCityDataSourceList data={myCityDataSources}>
        <Alert variant="warning">
          <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /> We were unable to identify any public examples of this kind of data. <Alert.Link href="#">Do you know of any?</Alert.Link>
        </Alert>    
      </UseCaseCityDataSourceList>

      <h5 className="dark-gray medium-headline mt-4 mb-3x">
        Related data standards:
      </h5>

      {/* show list if it exists / has items; otherwise display alert */}
      <DataStandardList data={props.dataStandards}>
        <Alert variant="warning">
          <FontAwesomeIcon icon={faExclamationCircle} /> We were unable to identify any relevant data standards, nor did we create any. <Alert.Link href="#">Do you know of one?</Alert.Link>
        </Alert>
      </DataStandardList>

    </div>
  );
}

export default UseCaseDataSourceListItem;