import React from "react";
import { ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

const UseCaseRoleListItem = (props) => {
  return (
    <ListGroupItem>
      <FontAwesomeIcon icon={(props.type === "Individual") ? faUserTie : faUsers} className="mr-3" />
      {props.name}
    </ListGroupItem>
  )
}

export default UseCaseRoleListItem;