import React from "react";
import { Container } from "reactstrap";
import { Alert } from "react-bootstrap";
import BackToTop from "../navs/BackToTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import CityDataSourcesList from "../CityDataSourcesList";
import citiesData from "../../data/cities.json";

const CityPage = (props) => {
  const city = citiesData.find(
    (thisCity) => thisCity.airtableId === props.match.params.id
  );
  let showAll = false;
  if (props.match.params.mode === "all") {
    showAll = true;
  }
  if (!city) {
    return <div>not found</div>;
  } else {
    return (
      <>
        <BackToTop />
        <div className="bg-header blackwhite-img mx-auto">
          <Container>
            <h2 className="secondary-header-text">City</h2>
            <h1 className="main-header-text internal-txt">{city.name}</h1>
          </Container>
        </div>
        <div>
          <Container className="pt-4 mb-4">
            <h4 className="medium-headline charcoal-black mt-4">
              What public data sources does this city have which support
              economic mobility outcomes?
            </h4>

            <CityDataSourcesList data={city.dataSources} showAll={showAll}>
              {/* this is only displayed when data is empty */}
              <Alert variant="danger">
                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                We were unable to display any public examples from this city.
                This may be a glitch in the matrix.
              </Alert>
            </CityDataSourcesList>
          </Container>
        </div>
      </> // Outer div
    );
  }
};

export default CityPage;
