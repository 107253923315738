import React from "react";
import { Container, Row, Col } from "reactstrap";

const Header = (props) => {
  return (
    <div className="bg-header home-img mx-auto">
      <Container>
        <Row>
          <Col xs="12">

            <h1 className="main-header-text">
              Connected Data for Economic Mobility
            </h1>

            <h2 className="secondary-header-text">Data Standards</h2>

            <p className="summary-header-text">
            A tool to help you discover data standards when making economic mobility related decisions. Connected data means that information on the impacts of policies and programs can be shared between cities and counties.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
