import React from "react";
import { Row } from "reactstrap";
import RoleCard from "../cards/RoleCard";

const RoleCardsList = (props) => {
  return (
    <div>
      {props.displayCount ? <div>{(props.data.length === 1) ? <>1 role shown</> : <>{props.data.length} roles shown.</>}</div> : <></>}
      <Row>
        {props.data.map((role) => {
          return <RoleCard key={role.airtableId} {...role} />
        })}
      </Row>
    </div>
  )
}

export default RoleCardsList;