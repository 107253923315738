import React from "react";
import { Link } from "react-router-dom";
import { Card, Button, Col } from "reactstrap";

const CityCard = (props) => {
  // calculate data sources to display
  let dataSourceCount = 0
  if (props.showAll) {
    dataSourceCount = props.dataSources.length;
  } else {
    dataSourceCount = props.dataSources.filter(dataSource => (dataSource.url)).length;
  }

  // only render a card if there is more than one data source
  if (dataSourceCount) {
    return (
      <Col sm="4" className="mt-3">
        <Card className="city-card text-center" body>
  
          <h3 className="navy medium-headline">{props.name}</h3>
  
          {props.dataSources && (
            <div>
  
              <h1 className="orange-number text-center">{dataSourceCount}</h1>
  
              <h5>Related Data Sources</h5>
  
            </div>
          )}
  
          <Link
            to={props.showAll ? `/city/${props.airtableId}/all` : `/city/${props.airtableId}`}
            component={Button}
            className="blue-button mt-4 mt-auto"
          >
            View Details
          </Link>
        </Card>
      </Col>
    );
  } else {
    return null;
  }

};

export default CityCard;
