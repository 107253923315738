import React from "react";
import { ListGroup } from "reactstrap";
import DataStandardListItem from "./DataStandardListItem";
import GatesUtilities from "./GatesUtilities";

const DataStandardList = (props) => {
  const dataStandards = GatesUtilities.sortByAttribute(props.data, "name");
  if (props.data && props.data.length > 0) {
      return (
        <ListGroup>
          {dataStandards.map((dataStandard) => {
            return <DataStandardListItem key={dataStandard.airtableId} {...dataStandard} />
          })}
        </ListGroup>
      )      
  } else {
    return <>{props.children}</>
  }
}

export default DataStandardList;