import React from "react";
import GatesUtilities from "./GatesUtilities";
import UseCaseDataSourceListItem from "./UseCaseDataSourceListItem";

const UseCaseDataSourceList = (props) => {
  if (props.data && props.data.length > 0) {
    const useCaseDataSources = GatesUtilities.sortByAttribute(props.data, "name");
    return (
      <>
        {useCaseDataSources.map((dataSource) => {
          return <UseCaseDataSourceListItem key={dataSource.airtableId} {...dataSource} />
        })}
      </>
    );  
  } else {
    return <>{props.children}</>
  }
}

export default UseCaseDataSourceList;