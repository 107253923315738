import React, { useState } from "react";
import logo from "../../images/gates_logo.png";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const TopNav = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar color="white" light expand="md">
        <a className="inline" href="/">
          <img src={logo} className="logo" alt="Gates Logo" />

          <h1 className="charcoal-black project-name-text mt-2">
            Economic Mobility
          </h1>
        </a>

        <NavbarToggler onClick={toggle} />

        <Collapse isOpen={isOpen} navbar>
          <Nav className="nav-links ml-auto mr-5" navbar>
            <NavLink href="/">Home</NavLink>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                About
              </DropdownToggle>

              <DropdownMenu left>
                <DropdownItem>
                  <NavLink href="/about-project">This Project</NavLink>
                </DropdownItem>

                <DropdownItem>
                  <NavLink href="/about-ds">What is a data standard?</NavLink>
                </DropdownItem>

                <DropdownItem>
                  <NavLink href="/about-methodology">Methodology</NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Explore
              </DropdownToggle>

              <DropdownMenu right className="ml-5">
                <DropdownItem>
                  <NavLink href="/outcomes">Decision Inventory</NavLink>
                </DropdownItem>

                <DropdownItem>
                  <NavLink href="/cities">Cities</NavLink>
                </DropdownItem>

                <DropdownItem>
                  <NavLink href="/standards">Data Standards</NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default TopNav;
