import React from "react";
import BackToTop from "../navs/BackToTop";
import { Container } from "reactstrap";
import TextFilterableList from "../TextFilterableList";
import CityCardsList from "../lists/CityCardsList";
import citiesData from "../../data/cities.json";

const CitiesPage = (props) => {
  let showAll = false;
  if (props.match.params.mode === "all") {
    showAll = true;
  }
  return (
    <>
      <BackToTop />
      <div className="bg-header blackwhite-img  mx-auto">
        <Container>
          <h1 className="main-header-text">City Data Sources</h1>
          <h2 className="secondary-header-text">Explore cities that have data for impact.</h2>
        </Container>
      </div>
      <div>
        <Container className="pt-4">

        <h2>Search Public City Data Sources</h2>

          <h5 className="mb-4">Below you can search for data sources in your city or view the data sources that your peer cities have shared. 
          You can search by city or the state abbreviation.</h5>


          <TextFilterableList 
            as={CityCardsList} 
            searchableAttributes={['name']} 
            data={citiesData} 
            showAll={showAll}
            placeholderText="Type to search for your city..."
            displayCount
          >
            <CityCardsList data={citiesData} showAll={showAll} displayCount>
           
            </CityCardsList>
          </TextFilterableList>
        </Container>
      </div>
    </>
  )
}

export default CitiesPage;