import React from "react";
import { Container, Row } from "reactstrap";

const AboutDSPage = (props) => {
  return (
    <>
      <div className="bg-header greenwave-img mx-auto">
        <Container>
          <h1 className="main-header-text">About Data Standards</h1>
          <h2 className="secondary-header-text">
            The Importance of Data Standards
          </h2>
        </Container>
      </div>

      <Container>
        <Row>
          <div>
            <h2 className="mt-5 mb-4 font-weight-bold">
              What is a data standard - and why is it important?
            </h2>

            <p>
              In the context of economic mobility, standardized data can help
              public sector employees make service-related decisions more
              quickly; collaborate with other cities or counties; and benchmark
              their own progress.
            </p>

            <p>
              Data standards may address language or terminology so that they
              may be used consistently. For example, the FBI has a program
              called uniform crime reporting, or UCR, which has specific
              definitions for terms such as “Arson” or “Robbery”, so national
              statistics can be compiled even when state and local laws define
              the terms differently. Because it’s impractical for the FBI to
              understand the different terminology in thousands of
              jurisdictions, law enforcement agencies are required to
              “translate” their own semantics into the UCR standard.
            </p>

            <p>
              Data standards may also define the structure of the data to be
              published.They include the names and descriptions of the columns
              of data. They often specify data types, such as “text”, “number”,
              or “date”. Schematic standards may also include how one dataset is
              related to another. This is common when data needs to be organized
              logically. For example, in BLDS, there is a dataset for permits
              and another for inspections. The BLDS specification allows
              inspection records to be associated with their corresponding
              permits.
            </p>

            <p>
              At their core, data standards help people discover, interact with,
              and add value to the information they have access to.
            </p>

            <p>
              To fully understand their power, consider the example of the most
              popular civic data standard: GTFS. Short for “General Transit Feed
              Specification”, GTFS helps public transportation systems share
              their routes, schedule, and fare data so that anyone planning a
              trip from one place to another knows when they can catch a train
              or a bus to their destination – and how much it will cost. While
              there are plenty of apps which work as trip planners, one of the
              more well-known ones is Google Maps. In fact, GTFS was
              co-developed by Google and Portland TriMet in 2006. TriMet
              published the data, and Google consumed it and made it available
              to anyone planning a trip in that area. Since 2006, GTFS has
              evolved to meet new needs and it has been adopted around the
              globe.
            </p>

            <p>
              Nearly everyone uses public transit at some point in their lives.
              Imagine how challenging it would be to use apps or websites if
              every public transit system published their data in different
              ways. Thanks to GTFS, you don’t have to.
            </p>

            <h2 className="mt-5 mb-4 font-weight-bold">Why now?</h2>

            <p>
              No matter who you are or where you come from, by working hard, you
              can achieve the American Dream. Financial opportunity and the
              prospect of upward mobility are deeply imbedded in the American
              story. While this ideal has inspired many Americans to “pull
              themselves up by the bootstraps” and countless others to immigrate
              to the US seeking opportunity, recent studies of economic mobility
              and reports of wage stagnation cast doubt on just how achievable
              the Dream is.
            </p>

            <p>
              Because of the fragmented nature of data sharing, disparate data
              systems, competing priorities of governments, and specific needs
              of individuals and families with barriers to economic mobility, it
              is not easy to determine whether government investments are
              achieving the desired long-term outcomes for residents, or to
              track the long-term impacts of programs and policies.
            </p>

            <h2 className="mt-5 mb-4 font-weight-bold">What’s next?</h2>

            <p>
              Check back here toward the end of 2020 to see how the data
              standards work has evolved.
            </p>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default AboutDSPage;