import React from "react";
import { ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const UseCaseCityDataSourceListItem = (props) => {
  return (
    <ListGroupItem>
      {props.url ? 
        (
          <>
            <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-3" />
            <a href={`${props.url}`} target="_blank" rel="noopener noreferrer">
              {props.name} ({props.city.name})
            </a>
          </>
        ) : 
        (
          <>
            {props.name} ({props.city.name});
          </>
        )}
  </ListGroupItem>
  )

}

export default UseCaseCityDataSourceListItem;
