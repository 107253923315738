import React from "react";
import { Container } from "reactstrap";
import BackToTop from "../navs/BackToTop";
import TextFilterableList from "../TextFilterableList";
import UseCaseCardsList from "../lists/UseCaseCardsList";
import useCases from "../../data/use_cases_details.json";
import Star from "../../images/star.png";

const UseCasesPage = (props) => {
  return (
    <>
      <BackToTop />
      <div className="bg-header blackwhite-img  mx-auto">
        <Container>
          <h1 className="main-header-text">Decision Inventory</h1>
          <h2 className="secondary-header-text internal-txt">Search our collection of decisions based on different scenarios or issues that your city may encounter.</h2>
        </Container>
      </div>

      <Container>

        <div id="useCases" className="mt-4 pt-4">

          <h3 className="mb-4 medium-headline">
            Data Collection Search Filter
          </h3>

          <h6 className="mb-3"><img className="twentyStar mr-2" src={Star} style={{width: '33px',height: 'auto'}} /> = A top 20 decision scenario identified by city partners.</h6>

          {/* TextFilterableList is 3 combined in 1:  Text Input Search with placeholder  2. Use Case Card Lists 3. Display Count */}
            <TextFilterableList as={UseCaseCardsList} withRadioFilter={true} data={useCases} placeholderText="Type to search for relevant words..." searchableAttributes={["impact"]} displayCount />
                 
          <BackToTop /> 
        </div>

      </Container>
    </>
  )
}

export default UseCasesPage;