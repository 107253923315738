export default class GatesUtilities {
  static sortByAttribute(array, attribute) {
    // test if object provided a populated array - if there's nothing to sort return the original
    if (!Array.isArray(array) || array.length === 0) {return array}

    // sort by specified attribute. if the objects don't have the supplied attribute, this will just return the original sort order
    return array.sort((a, b) => {
      if (a[attribute] < b[attribute]) {return -1}
      if (a[attribute] > b[attribute]) {return 1}
      return 0
    })
  }

  static filterUseCases(data, type, identifier) {
    switch(type) {
      case "topic":
      case "domain":
        return data.filter((useCase) => {
          if (useCase.domains) {
            for (const domain of useCase.domains) {
              if (domain.airtableId === identifier) {
                return true;
              }
            }
          }
          return false;
        });
      case "actor":
      case "role":
        return data.filter((useCase) => {
          if (useCase.actors) {
            for (const actor of useCase.actors) {
              if (actor.airtableId === identifier) {
                return true;
              }
            }
          }
          return false;
        });
      case "toptwenty":
        return data.filter(useCase => useCase.isTopTwenty);
      case "datastandard":
        return data.filter((useCase) => {
          if (useCase.dataSources) {
            return (
              useCase.dataSources.filter((dataSource) => {
                if (dataSource.dataStandards) {
                  return (
                    dataSource.dataStandards.filter((thisDataStandard) => {
                      return (
                        thisDataStandard.airtableId === identifier
                      );
                    }).length > 0
                  );
                }
                return false;
              }).length > 0
            );
          }
          return false;
        });
      default:
        return data;
    } 
  }

  static uniquify(array) {
    return [...new Set(array)];
  }
}