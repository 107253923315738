import React from "react";
import topicsData from "../../data/topics";
import EconDevIcon from "../../images/icons/topic_econ.png";
import EducationIcon from "../../images/icons/topic_education.png";
import HealthIcon from "../../images/icons/topic_health.png";
import HousinghIcon from "../../images/icons/topic_housing.png";
import HumanServicesIcon from "../../images/icons/topic_human_services.png";
import WorkforceIcon from "../../images/icons/topic_workforce.png";
import { Container, Media, ListGroup, ListGroupItem, FormGroup, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const icons = {
  Health: HealthIcon,
  "Human Services": HumanServicesIcon,
  Housing: HousinghIcon,
  Workforce: WorkforceIcon,
  Education: EducationIcon,
  "Economic Development": EconDevIcon,
};

const TopicsCaseResultsPage = (props) => {
  const topic = topicsData.find(
    (topic) => topic.airtableId === props.match.params.id
  );
  if (!topic) {
    return <div>not found</div>;
  }

  return (
    <>
      <div className="bg-header blackwhite-img  mx-auto">
        <Container>
          <h1 className="main-header-text">{topic.name} Topic Category</h1>
          <h2 className="secondary-header-text">
            {topic.useCaseCount} Outcomes Total
          </h2>
        </Container>
      </div>

      <Container className="mb-5">
        <h4 class="medium-headline charcoal-black mt-4">
          Selected Policy Topic Category:
        </h4>

        <Media href="#">
          <Media object src={icons[topic.name]} alt="Topic Category Icon" />
          <h3 className="topic-cat-header">{topic.name}</h3>
        </Media>

        <h4 class="dark-gray mt-5">
          The {topic.name} topic category has a total of
          <strong className="m-1">{topic.useCaseCount}</strong>
          outcomes.
        </h4>

        <FormGroup>
          <Input
            type="search"
            name="topicCasesSearch"
            id="topicCasesSearch"
            placeholder="Search for related outcomes..."
            className="search"
          />
        </FormGroup>

        <ListGroup>
          <ListGroupItem>
            <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-3" />
            <span className="text-primary">
              Use case text lorem ipsum solor dolor.
            </span>
            <em className="ml-1">Data in progress</em>
          </ListGroupItem>
          <ListGroupItem>
            <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-3" />
            <span className="text-primary">
              Use case text lorem ipsum solor dolor.
            </span>
            <em className="ml-1">Data in progress</em>
          </ListGroupItem>
          <ListGroupItem>
            <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-3" />
            <span className="text-primary">
              Use case text lorem ipsum solor dolor.
            </span>
            <em className="ml-1">Data in progress</em>
          </ListGroupItem>
          <ListGroupItem>
            <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-3" />
            <span className="text-primary">
              Use case text lorem ipsum solor dolor.
            </span>
            <em className="ml-1">Data in progress</em>
          </ListGroupItem>
          <ListGroupItem>
            <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-3" />
            <span className="text-primary">
              Use case text lorem ipsum solor dolor.
            </span>
            <em className="ml-1">Data in progress</em>
          </ListGroupItem>
        </ListGroup>
      </Container>
    </>
  );
}

export default TopicsCaseResultsPage;
