import React from "react";
import { Alert } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faDatabase, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import CityDataSourceUseCasesList from "./CityDataSourceUseCasesList";
import DataStandardList from "./DataStandardList";

const CityDataSourceListItem = (props) => {
  return (
    <div className="mb-4">
      <h4 className="light-orange medium-headline mt-5">
        <FontAwesomeIcon icon={faDatabase} className="mr-3" />
        {props.name}
      </h4>
        {props.url &&
          <h5 className="dark-gray medium-headline mb-3x">
            <a href={props.url} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-3" />
              View this data
            </a>
          </h5>
        }      
      <h5 className="dark-gray medium-headline mt-4 mb-3x">
        Related data standards
      </h5>

      {/* show list if it exists / has items; otherwise display alert */}
      <DataStandardList data={props.dataStandards}>
        <Alert variant="warning">
          <FontAwesomeIcon icon={faExclamationCircle} /> We were unable to identify any relevant data standards, nor did we create any. <Alert.Link href="#">Do you know of one?</Alert.Link>
        </Alert>
      </DataStandardList>

      <h5 className="dark-gray medium-headline mt-4 mb-3x">
        Related city decisions
      </h5>

      {/* show list if it exists / has items; otherwise display alert */}
      <CityDataSourceUseCasesList data={props.useCaseDataSources}>
        <Alert variant="warning">
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" /> We cannot display any outcomes for this city dataset. This is likely a glitch in the matrix.
        </Alert>    
      </CityDataSourceUseCasesList>

    </div>
  )
}

export default CityDataSourceListItem;
