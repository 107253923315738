import React from "react";
import { ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faColumns } from "@fortawesome/free-solid-svg-icons";

const DataStandardListItem = (props) => {
  return (
    <ListGroupItem>
      <FontAwesomeIcon icon={faColumns} className="mr-3" />
        <Link to={`/standard/${props.airtableId}`}>
          {props.name}
        </Link>
    </ListGroupItem>
  )

}
export default DataStandardListItem;