import React from "react";
import { Accordion } from "react-bootstrap"

import DataStandardTable from "./DataStandardTable";

const DataStandardTableList = (props) => {
  return(
    <Accordion>
      {props.data.map((table) => {
        return <DataStandardTable key={table.airtableId} {...table} />
      })}
    </Accordion>
  )

}

export default DataStandardTableList;