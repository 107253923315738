import React from "react";
import { Row } from "reactstrap";
import UseCaseCard from "../cards/UseCaseCard";
import GatesUtilities from "../GatesUtilities";


const UseCaseCardsList = (props) => {
  const useCases = GatesUtilities.sortByAttribute(props.data, "impact");
  return (
    <div>
      {props.displayCount && <div>{(props.data.length === 1) ? <>Showing 1 decision</> : <h6>Showing <strong>{props.data.length}</strong> decisions.</h6>}</div>}
      <Row>
      {useCases.map((useCase) => {
          return <UseCaseCard key={useCase.airtableId} {...useCase} />
        }
      )}
      </Row>
    </div>
  )
}

export default UseCaseCardsList;