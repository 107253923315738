import React from "react";
import BackToTop from "react-easy-back-to-top";

export default function Top() {
  return (
    <>
      <BackToTop
        icon="scroll-top"
        text="&uarr;"
        backgroundColor="#FD903E"
        position={{ right: "5%", bottom: "10%" }}
        hover={{ backgroundColor: "#c05a0a", color: "white" }}
        transition="all 0.5s"
        showOnDistance={0}
        borderRadius={30}
        opacity="1"
        color="white"
        fontSize="24px"
      />
    </>
  );
}
