import React from "react";
import { Container, Row } from "reactstrap";
import map from "../../images/city_map.png";

const AboutProjPage = (props) => {
  return (
    <>
      <div className="bg-header greenwave-img mx-auto">
        <Container>
          <h1 className="main-header-text pt-4">
            About the Connected Data for Economic Mobility Project
          </h1>
          <h2 className="secondary-header-text">
            Helping cities to standardize and share their data.
          </h2>
        </Container>
      </div>

      <Container>
        <Row>
          <div className="mb-5">
            <h2 className="mt-5 mb-4 font-weight-bold">
              About the Connected Data for Economic Mobility Project
            </h2>

            <p>
              The goal of the data standards is to ultimately support real-time
              access to data and analysis to help public sector decision makers
              better understand the needs of populat ions, evaluate programs and
              policies, and collaborate across borders to achieve positive,
              long-term economic outcomes for residents. Check back here to see
              the data standards once they are published!
            </p>

            <p>
              By co-creating an economic mobility data standard with a
              representative group of staff from US cities, the Center for
              Applied Public Research will help city staff standardize and share
              data with their counterparts in a way that allows them to make
              better data-informed decisions about city services.
            </p>

            <p>In April of 2019, representatives from over 30 cities in the United States convened in Baltimore to talk about their current data sharing 
practices, as well as opportunities to share data across departments and with one another.</p>

           <img src={map} className="img-fluid" alt="City Map Logo" />

          </div>
        </Row>
      </Container>
    </>
  );
}

export default AboutProjPage;