import React from "react";
import { ListGroup } from "reactstrap";
import GatesUtilities from "./GatesUtilities";
import UseCaseRoleListItem from "./UseCaseRoleListItem";

const UseCaseRoleList = (props) => {
  if (props.data && props.data.length > 0) {
    const useCaseRoles = GatesUtilities.sortByAttribute(props.data, "name");
    return (
      <ListGroup>
        {useCaseRoles.map((role) => {
          return <UseCaseRoleListItem key={role.airtableId} {...role} />
        })}
      </ListGroup>
    )
  
  } else {
    return <>{props.children}</>
  }
}

export default UseCaseRoleList;