import React from "react";
import { Table, Badge } from "reactstrap";
import { Alert } from "react-bootstrap"
import DataStandardTableAllowedValuesList from "./DataStandardTableAllowedValuesList"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const DataStandardTable = (props) => {
  const dataTypeColors = {
    "Text": "info",
    "Number": "primary",
    "Boolean": "warning",
    "String": "success",
    "Date": "danger"
  }
  return (
    <>
      <h4 className="mt-2 mb-2">
        {props.name}
        <span className="ml-3 medium-headline dark-pumpkin">
          Data Structure
        </span>
      </h4>

      {(props.description) &&
        <>
          <h5>About this table</h5>
          <p>{props.description}</p>
        </>
      }

      {props.fields ? 
        <>
          <p>Each row below represents one field or column in this table.</p>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Presence</th>
                <th>Data Type</th>
              </tr>
            </thead>
            <tbody>
              {props.fields.map((field) => {
                return (
                  <tr key={field.airtableId}>
                    <td>{field.name}</td>
                    <td>
                      {field.description} <br />
                      {field.allowedValues ? <><DataStandardTableAllowedValuesList values={field.allowedValues} eventKey={field.airtableId} /></> : <></>}
                    </td>
                    <td>{field.presence}</td>
                    <td>
                      <Badge key={field.airtableId} color={dataTypeColors[field.dataType]} className="ml-2 p-2">
                        {field.dataType}
                      </Badge>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      :
        <Alert variant="secondary"><FontAwesomeIcon icon={faTimes} className="mr-3" />We do not have detailed information about this data structure.</Alert>
      }

      
    </>
  );
}

export default DataStandardTable;