import React from "react";
import { Container, Row, Col } from "reactstrap";
import figure1 from "../../images/figure1.png";
import figure2 from "../../images/figure2.png";



const MethodPage = (props) => {
  return (
    <div>
      <div className="bg-header blackwhite-img mx-auto">
        <Container>
          <h1 className="main-header-text">Methodology</h1>
          <h2 className="secondary-header-text">Our Process</h2>
        </Container>
      </div>

      <Container>
          
          <h2 className="mt-5 mb-4 font-weight-bold">
             City Preselection for Economic Mobility Project
            </h2>

            <h4>The purpose of this analysis is to identify groups of similar cities based on different indicators of economic mobility.</h4>

        <ol>
          <li className="mt-3 mb-2">Description of the data</li>
 
            <p>
              We aimed to leverage existing research into intergenerational and geographic elements of economic mobility, drawing data
 from the Opportunity Insights “Opportunity Atlas” as well as the Economic Innovation Group Distressed Communities Index to 
filter and create a multi-dimensional score of U.S. cities.
            </p>

            <p>
            The Opportunity Atlas (Chetty et al. 2014) is an extensive study of the chances that children have to improve their income relative
 to their parents according to the neighborhood they were born. Our analysis focuses on the Atlas research on intergenerational 
mobility (Fig. 1), comparing the income rank of parents with the mean income rank of their children at age 26 within birth cohorts. 
</p>

<Row>

<Col md="6">
<img src={figure1} className="img-fluid" alt="Figure 1" />
</Col>

<Col md="6">
<p>The slope represents the chances for children to rise in the income rank: a slope equal to zero means that a child can end up in any 
income rank regardless of the income of the parents, while larger slopes represent a more significant barrier to upward mobility. The 
intercept of the trend indicates the mean income rank for children at age 26 whose parents were in the lowest income rank. We will use 
the slope and intercept of these intergenerational mobility trends (IMT) from the Opportunity Atlas for birth cohorts from 1980 to 1986.</p>
</Col>

</Row>

           

<p>Our second source of information is the Distressed Communities Index (DCI) developed by the Economic Innovation Group. The DCI combines 
seven variables into a single measure of economic well-being:</p>

<li>Analysis</li>

<p>Limiting the dataset to geographies that were examined by both the Opportunity Atlas and the DCI yielded a list of 221 cities. Cities 
such as Mesa, AZ, Long Beach, CA, and Oakland, CA were either absent or aggregated differently between the two datasets, and were not included.</p>

<p>We first explore the relationship between the IMT slopes and intercepts. Figure 2 shows the data for children born in 1980 and 1986, 
where each point corresponds to a different city. As expected for a linear trend like the one in Figure 1, its slope and intercept are 
correlated, i.e. the steeper the trend (higher slope), the lower the intercept of the line with the y axis will be. Therefore, as no new 
information is provided by the intercept, we will focus our analysis on the slope.</p>

<p>It might be interesting to note that despite the similar behaviour we see in the plots for 1980 and 1986, it seems that the intercept vs. 
slope relationship was narrower in 1980. Since both the range of the slope and the range of the intercept have diminished in recent
years, this would mean that these cities are converging to a similar behaviour regarding these two metrics of economic mobility. The 
direction of this common behaviour is not towards lower slopes and higher intercepts (an overall positive shift), but towards an 
intermediate value (potentially a net neutral shift). However, additional data and further analysis would be necessary to confirm 
this hypothesis.</p>


           <img src={figure2} className="img-fluid" alt="Figure 2" />

<p>
The time evolution of the IMT slopes for children born between 1980 and 1896 is shown in Figure 3 for several cities. The value of the slope is in some cases highly variable for children born just one year apart in the same city, which raises doubts about the robustness of the measure at this granularity. 

In order to account for this variability, we are going to compute a new variable defined as the addition of the absolute yearly slope changes minus the absolute slope change between 1980 and 1986: 
</p>

<p>
Where S is the slope and y is the year. We subtract the slope absolute change between 1980 and 1986 to account only for the variability that does not contribute to the overall evolution of the slope with time. Therefore, a city with low variability would have steady changes with time. The highest variability among the cities in our sample is found in Great Falls, MT, while a more stable slope can be found in Houston, TX (see Figure 3). The additional variability may be related to the smaller population of these cities, while larger cities provide a more reliable sample over time.
</p>

<li className="mt-3">Cluster selection</li>

<p>We create three clusters from the original list of cities following these criteria:</p>

<p>The final clusters appear color coded in figure 5, where grey color correspond to those cities out of any subset due to either 
their variability or their location in the IMT slope - DCI diagram.</p>

<p>There are a few cities that are in principle categorized as medium (see fig. 5) but appear slightly closer to the first group 
than to the medium: Denver, CO, Boise City, ID, Austin, TX, and Madison, WI. One might want to consider moving them to the first subset.</p>





       </ol>
  
      </Container>
    </div>
  );

}

export default MethodPage;