import React from "react";
import Header from "../Header";
import { Link } from "react-router-dom";
import { Container, Button } from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import GatesUtilities from "../GatesUtilities";
import UseCasesCardList from "../lists/UseCaseCardsList";
import useCasesData from "../../data/use_cases_details.json";
import TextFilterableList from "../TextFilterableList";
import Star from "../../images/star.png";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.light,
  },
  tooltip: {
    fontSize: 17,
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: '3px 3px 3px 3px rgba(0, 0, 0, .2);'
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const LandingPage = (props) => {
  return (
    <div>
      <Header id="top" />

      <Container>
        <div>
          <h4 className="medium-headline charcoal-black mt-4">Introduction</h4>
          <p className="dark-gray mb-2">
            The Connected Data for Economic Mobility data dictionary involved
            representatives from over 60 cities in the United States. By
            co-creating an economic mobility data standard with a representative
            group of staff from US cities, the Center for Applied Public
            Research is helping city staff standardize and share data with their
            counterparts in a way that allows them to make better data-informed
            decisions about city services.
          </p>
        </div>

        <div className="mt-3">
          <Link to={`outcomes`}>
            <BootstrapTooltip
              title="Explore policy and operational decisions that your city can make here."
              placement="bottom"
            >
              <Button outline className="btn-outline-navy mr-2" size="lg">
                Explore Decision Inventory
              </Button>
            </BootstrapTooltip>
          </Link>

          <Link to={`standards`}>
            <BootstrapTooltip
              title="Review technical specifications for data standards here."
              placement="bottom"
            >
              <Button outline className="btn-outline-navy mr-2" size="lg">
                Discover Data Standards
              </Button>
            </BootstrapTooltip>
          </Link>

          <Link to={`cities`}>
            <BootstrapTooltip
              title="Review datasets cities are publishing."
              placement="bottom"
            >
            <Button outline className="btn-outline-navy mr-2" size="lg">
              Find City Data Sources
            </Button>
            </BootstrapTooltip>
          </Link>
        </div>

        <div id="useCases" className="mb-5">
          <h4 className="dark-orange-headline mt-5 mb-4">
            <img className="twentyStar mr-2" src={Star} alt="Star Icon" />
            Top 20 Economic Mobility Related City Decisions
          </h4>
          <p>
            The most important decisions and scenarios identified by our city
            partners. Below you can view which economic-mobility related
            decision that can relate to your city. Or you can view all options
            in the{" "}
            <strong>
              <Link to={`/outcomes`}>decision inventory</Link>
            </strong>
            .
          </p>

          <TextFilterableList
            as={UseCasesCardList}
            withRadioFilter={true}
            data={GatesUtilities.filterUseCases(useCasesData, "toptwenty")}
            placeholderText="Type to search for relevant words..."
            searchableAttributes={["impact"]}
            displayCount
          />

          <p className="text-center">
            <br />
            <br />
            <Link
              to={`/outcomes`}
              component={Button}
              className="blue-button mt-4 m-auto text-center"
            >
              Search All Decisions
            </Link>
            <br />
            <br />
          </p>
        </div>
      </Container>
    </div>
  );
};

export default LandingPage;
