import React from "react";
import { Row } from "reactstrap";

import DataStandardCard from "../cards/DataStandardCard";
import GatesUtilities from "../GatesUtilities";

const DataStandardCardsList = (props) => {
  const dataStandards = GatesUtilities.sortByAttribute(props.data, "name")
  return (
    <div>
      {props.displayCount && <div>{(props.data.length === 1) ? <>1 data standard shown</> : <>{props.data.length} data standards shown.</>}</div>}
      <Row>
      {dataStandards.map((thisDataStandard) => {
          return <DataStandardCard key={thisDataStandard.airtableId} {...thisDataStandard} />
        }
      )}
      </Row>
    </div>

  )
}

export default DataStandardCardsList;