import React from "react";
import { Link } from "react-router-dom";
import { Col, Card, Button, Badge } from "reactstrap";
import aprLogo from "../../images/logo_apr.png";

const DataStandardCard = (props) => {
  return (
    <Col sm="4" className="text-center mb-2">
      <Card className="ds-card hvr-shadow" body>
        <h4 className="navy medium-headline">{props.name}</h4>

        <h5>
          {props.draftedByCivicImpact ? (
            <div>
               <Badge color="warning">Applied Public Research</Badge>
            </div>
          ) : (
            <div>
              <Badge color="secondary">External Data Source</Badge>
            </div>
          )}
        </h5>

        <Link
          to={`/standard/${props.airtableId}`}
          component={Button}
          className="blue-button"
        >
          View Data Standard
        </Link>
      </Card>
    </Col>
  );
}

export default DataStandardCard;
