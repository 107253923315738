import React from "react";
import DomainTagsList from "../other/DomainTagsList";
import BackToTop from "../navs/BackToTop";
import UseCaseRoleList from "../UseCaseRoleList";
import UseCaseDataSourceList from "../UseCaseDataSourceList";
import { Container } from "reactstrap";
import { Alert } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import useCasesData from "../../data/use_cases_details";

function UseCasePage(props) {
  const useCase = useCasesData.find(
    (thisUseCase) => thisUseCase.airtableId === props.match.params.id
  );
  if (!useCase) {
    return <div>not found</div>;
  } else {
    return (
      <>
        <BackToTop />
        <div className="bg-header blackwhite-img  mx-auto">
          <Container>
            <h2 className="secondary-header-text">City Decision</h2>
            <h1 className="main-header-text internal-txt">{useCase.impact}</h1>
          </Container>
        </div>
        <div>
          <Container className="pt-4">
            <h5>Related Policy Topic Categories:</h5>

            <DomainTagsList domains={useCase.domains} />

            <h3 className="medium-headline charcoal-black mt-4">
              What data-informed activity might support this outcome?
            </h3>

            <h4 className="dark-gray use-case-txt">{useCase.name}</h4>

            <br />
            <h4 className="navy medium-headline mt-3">
              Who might be best suited to help with this activity and outcome?
            </h4>
            <UseCaseRoleList data={useCase.actors} />
            <br />
            <h4 className="navy medium-headline mt-3">
              What kinds of data might be useful for this activity?
            </h4>

            <p className="medium-gray"></p>

            {/* show list if it exists / has items; otherwise display alert */}
            <UseCaseDataSourceList data={useCase.dataSources}>
              <Alert variant="warning">
                <FontAwesomeIcon icon={faExclamationCircle} />
                We are unable display any data sources. This may be a glitch in
                the matrix.
              </Alert>
            </UseCaseDataSourceList>
          </Container>
        </div>
        {/* End of Body div that starts above the header */}
      </> // Outer div
    );
  }
}

export default UseCasePage;
