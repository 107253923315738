import React from "react";
import { Table, Card, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";


const DataStandardTableAllowedValuesList = (props) => {
  return (
    <Card border="info">
      <Accordion.Toggle as={Card.Header} eventKey={props.eventKey} style={{cursor: "pointer"}}>
        <FontAwesomeIcon icon={faChevronCircleDown} className="mr-1" /> This field may only contain specific values. 
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={props.eventKey}>
        <Card.Body>
          <Table size="sm" striped>
            <thead>
              <tr><th>Value</th><th>Description</th></tr>
            </thead>
            <tbody>
              {props.values.map((value) => { return (
                <tr key={value.airtableId}>
                  <td>{value.name}</td>
                  <td>{value.description}</td>
                </tr>
              )})}
            </tbody>
          </Table>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  )
}

export default DataStandardTableAllowedValuesList;