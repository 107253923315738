import React from "react";
import UseCaseCardsList from "./lists/UseCaseCardsList"

const CityDataSourceUseCasesList = (props) => {
  // compile an array of use cases from the data sources 
  // TODO do this in the cities.json data model instead of in this component
  const useCases = [];
  if (props.data) {
    for (const useCaseDataSource of props.data) {
      if (useCaseDataSource.useCases) {
        for (const useCase of useCaseDataSource.useCases) {
          useCases.push(useCase);
        }
      }
    }
    if (useCases.length > 0) {
      return <UseCaseCardsList data={useCases} displayCount/>
    } else {
      return props.children || null
    }
  }
  return props.children || null
}

export default CityDataSourceUseCasesList;