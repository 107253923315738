import React from "react";
import RoleCardsList from "./RoleCardsList";
import { Container, FormGroup, Input } from "reactstrap";

import rolesData from "../../data/roles.json";

function RoleCardsListFunctionalWithFilter(props) {
  const [roleTerm, setSearchTerm] = React.useState("");
  const [roleResults, setSearchResults] = React.useState([]);
  const handleRolesChange = (e) => {
    setSearchTerm(e.target.value);
  };
  React.useEffect(() => {
    const results = rolesData.filter((role) => {
      const term = roleTerm.trim().toLowerCase();

      if (!term) {
        return true;
      }

      if (role.name.toLowerCase().includes(term)) {
        return true;
      }

      for (let i = 0; i < role.name.length; i++) {
        const name = role.name[i];
        if (name.toLowerCase().includes(term)) {
          return true;
        }
      }
      return false;
    });
    setSearchResults(results);
  }, [roleTerm]);

  return (
    <Container>
    <div id="roles" className="mt-5 mb-5">
      <h4 className="medium-headline">Roles</h4>

      <h5 className="dark-gray mb-4">
        There is currently a total of <strong>{rolesData.length}</strong> roles
        available. Search for your role or job title to be matched with relevant
        outcomes.
      </h5>

      <FormGroup>
        <Input
          value={roleTerm}
          onChange={handleRolesChange}
          type="search"
          name="rolesSearch"
          id="rolesSearch"
          placeholder="Type to Search for your role..."
          className="search"
        />
      </FormGroup>

        {roleResults && roleResults.length ? (
          <RoleCardsList roles={roleResults} />
        ) : (
          <h5 className="font-weight-bold mt-2">Results not found.</h5>
        )}

    </div>

    </Container>
  );
}

export default RoleCardsListFunctionalWithFilter
