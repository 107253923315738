import React from "react";
import rolesData from "../../data/roles.json";
import { Container, ListGroup, ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const RolePage = (props) => {
  const role = rolesData.find((role) => role.airtableId === props.match.params.id)
  if (!role) {
    return <div>not found</div>
  } else {
    return (
      <>
        <div className="bg-header blackwhite-img mx-auto">
          <Container>
            <h1 className="main-header-text">{role.name} Role</h1>
            <h2 className="secondary-header-text">
              {role.useCaseCount} Outcomes Total
            </h2>
          </Container>
        </div>
  
        <Container>
          <h4 class="charcoal mt-5">
            The {role.name} role has a total of
            <strong className="ml-1">{role.useCaseCount}</strong> outcomes.
          </h4>
  
          <h4>
            <em>Data in progress</em>
          </h4>
          <div className="mt-3 mb-5">
            <ListGroup>
              <ListGroupItem>
                <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-3" />
                <span className="text-primary">
                  Use case text lorem ipsum solor dolor.
                </span>
                <em className="ml-1">Data in progress</em>
              </ListGroupItem>
              <ListGroupItem>
                <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-3" />
                <span className="text-primary">
                  Use case text lorem ipsum solor dolor.
                </span>
                <em className="ml-1">Data in progress</em>
              </ListGroupItem>
              <ListGroupItem>
                <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-3" />
                <span className="text-primary">
                  Use case text lorem ipsum solor dolor.
                </span>
                <em className="ml-1">Data in progress</em>
              </ListGroupItem>
              <ListGroupItem>
                <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-3" />
                <span className="text-primary">
                  Use case text lorem ipsum solor dolor.
                </span>
                <em className="ml-1">Data in progress</em>
              </ListGroupItem>
              <ListGroupItem>
                <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-3" />
                <span className="text-primary">
                  Use case text lorem ipsum solor dolor.
                </span>
                <em className="ml-1">Data in progress</em>
              </ListGroupItem>
            </ListGroup>
          </div>
        </Container>
      </>
    );
  }
}

export default RolePage;