import React from "react";
import { Container, Row, Col, FormGroup, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import RadioFilter from "./other/RadioFilter";
import Star from "../images/star.png";

const StarImg = () => <img className="twentyStar" src={Star} alt="top twenty star" />

// this is a data & list-agnostic component to add text search box and filter to any list container component

// it can be optimized a lot by pre-processing the data (eg .trim().toLower() the searchableAttributes 
//   and adding them to a map[key = attributeValue, value = dataItem]), then running .includes(searchTerm) on each key
const TextFilterableList = (props) => {
  if (!props.as) {
    throw new Error(
      "A child component is required, using the 'as={}' JSX attribute."
      );
  }
  const ChildListObject = props.as; //must be capitalized to be recognized in a JSX element tag
  if (!props.searchableAttributes) {
    throw new Error(
      "A array of object attribute names is required, using the 'searchableAttributes={[]} JSX attribute."
      );
  }
  const searchableDataAttributes = props.searchableAttributes;
  const placeholderText = props.placeholderText
  ? props.placeholderText
  : "Type to search for an item below...";

  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState(props.data);
  const [domainFilterFn, setDomainFilterFn] = React.useState(null);
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  React.useEffect(() => {
    if(typeof domainFilterFn==='function' && !domainFilterFn()){}

    //if no search term, pass all data values to child list
  if ((!searchTerm || !searchTerm.trim().length) && typeof domainFilterFn!=='function') {
    setSearchResults(props.data);
  } else {
    const termToFind = searchTerm.trim().toLowerCase();
    const results = props.data.filter((dataItem) => {

      if(typeof domainFilterFn==='function' && !domainFilterFn(dataItem)){ 
        return false;
      }
      if(!termToFind){
        return true;
      }

      for (const dataAttribute of searchableDataAttributes) {
        if (dataItem[dataAttribute]) {
          if (
            dataItem[dataAttribute]
            .toString()
            .toLowerCase()
            .includes(termToFind)
            ) {
            return true;
        }
      }
    }
    
    return false;
  });
    setSearchResults(results);  
  }
}, [searchTerm, props.data, searchableDataAttributes, domainFilterFn]);

  return (
<div>

      <Row>
        <Col md="4">
        <Input
        value={searchTerm}
        onChange={handleSearchTermChange}
        type="search"
        name="textSearch"
        id="textSearch"
        placeholder={placeholderText}
        className="search"
        />

        <FontAwesomeIcon icon={faSearch} id="searchIcon" />
        </Col>

        <Col md="8">
        { props.withRadioFilter && <RadioFilter setDomainFilterFn={setDomainFilterFn} /> }
        
        </Col>
      </Row>


    <ChildListObject {...props} data={searchResults} />
  
        </div>
    );
};

export default TextFilterableList;
