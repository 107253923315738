import React from "react";
import { Container } from "react-bootstrap";
import TextFilterableList from "../TextFilterableList";
import UseCaseCardsList from "../lists/UseCaseCardsList";
import RoleCardsList from "../lists/RoleCardsList";
import useCases from "../../data/use_cases_details.json";
import roles from "../../data/roles.json";


const TestContainerPage = (props) => {
  return (
    <Container>
      <TextFilterableList as={UseCaseCardsList} data={useCases} searchableAttributes={['impact']} placeholderText="Find an outcome..." />
      <hr />
      <TextFilterableList as={RoleCardsList} data={roles} searchableAttributes={['name']} />
    </Container>

  )
}

export default TestContainerPage;