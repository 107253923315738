import React from "react";
import { Link, Route } from "react-router-dom";
import DomainTagsList from "../other/DomainTagsList";
import { Card, Button, Col} from "reactstrap";
import Star from "../../images/star.png";

const inlineFlex = {
  display: 'inline-flex'
}

const StarImg = () => <img className="twentyStar" src={Star} alt="top twenty star" />

const UseCaseCard = (props) => {

const truncate  = (str) => {
    return str.length > 108 ? str.substring(0, 111) + "..." : str;
  }
  
  return (
    <Col sm="4" className="mt-3">
        <Card className="usecase-card hvr-shadow" body>

        <div style={inlineFlex}>

        { props.isTopTwenty ? 
          <Route path="/:path" component={StarImg} /> : null
        }

        <span className="card-text dark-gray m-3" style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>   
        {truncate(props.impact)}
        </span>
        
        </div>

        <div className="text-center"><DomainTagsList domains={props.domains} /></div> 

        <Link
        to={`/outcome/${props.airtableId}`}
        component={Button}
        className="blue-button ml-5 mt-2"
        >
        View More Details
        </Link>


        </Card>
    </Col>
    );
};

export default UseCaseCard;
