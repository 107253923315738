import React from "react";
import { Row } from "reactstrap";

import CityCard from "../cards/CityCard";
import GatesUtilities from "../GatesUtilities";

const CityCardsList = (props) => {
  const cities = GatesUtilities.sortByAttribute(props.data, "name");
  return (
    <div>
      {props.displayCount && <div>{(props.data.length === 1) ? <>1 city shown</> : <h6 class="mt-4"><strong>{props.data.length}</strong> cities shown</h6>}</div>}
      <Row>
        {cities.map((thisCity) => {
            return <CityCard key={thisCity.airtableId} {...thisCity} showAll={props.showAll} />
          }
        )}
      </Row>
    </div>
  )
}

export default CityCardsList;