import React from "react";
import useCasesData from "../../data/use_cases_details.json";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import TopCircle from "../../images/top.png";
import { Tag } from "antd";
import {
  Card,
  Button,
  Row,
  Col,
  Form,
  Label,
  FormGroup,
  Input,
} from "reactstrap";


const domainColors = {
  Health: "geekblue",
  Housing: "red",
  Education: "volcano",
  Workforce: "cyan",
  "Human Services": "magenta",
  "Economic Development": "purple",
};


function DomainRadioFilter({setDomainFilterFn}) {

  const [selectedDomain, setSelectedDomain] = React.useState("all");
  const [searchResults, setSearchResults] = React.useState([]);

  React.useEffect(()=>{
    //console.log('selectedDomain:', selectedDomain)
    setDomainFilterFn(()=>{
      if(!selectedDomain || selectedDomain==='all'){
        return null;
      }
      return (dataItem) => {
        //console.log("THE DOMAiN FILTER:", selectedDomain)
          if(!dataItem || !dataItem.domains) { return false; }
          const hasSelectedDomain = dataItem.domains.find(
            (domain) => domain.name.toLowerCase() === selectedDomain
          );

          return hasSelectedDomain;
        }
      }
    );
  },[selectedDomain]);

  
  const handleRadioChange = (e) => {
    setSelectedDomain(e.target.value);
  };
  return (
    <div id="useCases" className="mb-5">
  
      <Form className="inline">
    
        <Row className="mt-2 ml-5">
          <FormGroup checked>
            <Label check className="pr-2">
              <Input
                onChange={handleRadioChange}
                type="radio"
                name="radio"
                id="showAll"
                value="all"
                className="form-check-input"
                checked={selectedDomain === "all"}
              />
              Show All
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check>
              <Input
                onChange={handleRadioChange}
                type="radio"
                name="radio"
                value="health"
                checked={selectedDomain === "health"}
              />
              <Tag color="geekblue">Health</Tag>
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check>
              <Input
                onChange={handleRadioChange}
                type="radio"
                name="radio"
                value="housing"
                checked={selectedDomain === "housing"}
              />
              <Tag color="red">Housing</Tag>
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check>
              <Input
                onChange={handleRadioChange}
                type="radio"
                name="radio"
                value="education"
                checked={selectedDomain === "education"}
              />
              <Tag color="volcano">Education</Tag>
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check>
              <Input
                onChange={handleRadioChange}
                type="radio"
                name="radio"
                value="workforce"
                checked={selectedDomain === "workforce"}
              />
              <Tag color="cyan">Workforce</Tag>
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check>
              <Input
                onChange={handleRadioChange}
                type="radio"
                name="radio"
                value="human services"
                checked={selectedDomain === "human services"}
              />
              <Tag color="magenta">Human Services</Tag>
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check>
              <Input
                onChange={handleRadioChange}
                type="radio"
                name="radio"
                value="economic development"
                checked={selectedDomain === "economic development"}
              />
              <Tag color="purple">Economic Dev</Tag>
            </Label>
          </FormGroup>
        </Row>
      </Form>
    </div>
  );
}


function UseCase({ name, airtableId, domains }) {
  return (
  <div>
  </div>
  );
}

export default DomainRadioFilter;