import React from "react";
// import topicsData from "../../data/topics";

const TopicsPage = (props) => {
  // const topic = topicsData.find((topic) => topic.airtableId === props.match.params.id);
  return (
    <div>
      placeholder!
    </div>
  ); 
}
export default TopicsPage;