import React from "react";
import { Tag } from "antd";

const DomainTag = (props) => {
  const domainColors = {
    "Health": "geekblue",
    "Housing": "red",
    "Education": "volcano",
    "Workforce": "cyan",
    "Human Services": "magenta",
    "Economic Development": "purple",
  };
  return (
    <Tag key={props.airtableId} color={domainColors[props.name] || "gray"} className="mt-auto">
      {props.name}
    </Tag>
  );
}

export default DomainTag;